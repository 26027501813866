import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import cx from "classnames";

import { useLatestPosts } from "../../../GraphQl/useLatestPosts";
import { BlockWrapper } from "../blockWrapper";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

const NewsTabs = ({ blockConfig, allnewslink, articlelinktext, text }) => {
  const posts = useLatestPosts();

  const featuredPost = posts[0];
  const otherPosts = posts.slice(1, 5);

  const NewsCard = ({ heading, slug, _rawImage, id }) => {
    return (
      <div>
        <Link to={slug.current}>
          {_rawImage && <Image {..._rawImage} />}
          <h3
            className={cx("text-dark-navy my-5", {
              "text-4xl leading-12": id === featuredPost.id,
              "text-xl leading-8": id !== featuredPost.id,
            })}
          >
            {heading}
          </h3>
          <p className="font-bold text-teal text-xl">Read more &gt;</p>
        </Link>
      </div>
    );
  };

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14">
        <PortableTextBlock text={text} className="prose-news leading-16" />
      </div>

      <div className="col-start-2 col-end-14 flex flex-row gap-16 mt-24">
        <div className="w-full">
          <NewsCard {...featuredPost} />
        </div>

        <div className="w-full grid grid-cols-2 gap-8">
          {otherPosts.map((post, i) => {
            return <NewsCard key={i} {...post} />;
          })}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default NewsTabs;
