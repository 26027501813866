import cx from "classnames";
import { useEmblaCarousel } from "embla-carousel/react";
import Image from "gatsby-plugin-sanity-image";
import React from "react";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { BlockWrapper } from "../blockWrapper";

const FullCard = (props) => {
  const i = props.row[1];
  const row = props.row[0];
  const reverse = props.reverse;
  const transparent = props.transparent;

  return (
    <div
      className={cx(
        "col-start-2 col-end-14 py-8 lg:px-20 rounded-2xl flex gap-16 lg:mx-12 my-7 flex-col lg:flex-row ",
        {
          "lg:flex-row":
            (i % 2 === 0 && reverse === false) ||
            (i % 2 !== 0 && reverse === true),
          "lg:flex-row-reverse":
            (i % 2 !== 0 && reverse === false) ||
            (i % 2 === 0 && reverse === true),
          "bg-slate/5 px-gutter lg:py-32": transparent !== true,
          "lg:py-24": transparent === true,
        }
      )}
    >
      <div
        className={cx("flex items-center justify-center w-full", {
          "w-full": reverse === false,
          "w-full lg:w-1/3": reverse === true,
        })}
      >
        <Image {...row.image} className="max-h-48 w-auto lg:max-h-80 py-4" />
      </div>

      <div
        className={cx("flex flex-col items-center justify-center w-full", {
          "w-full": reverse === false,
          "w-full lg:w-2/3": reverse === true,
        })}
      >
        <PortableTextBlock text={row.text} />
      </div>
    </div>
  );
};

const AlternatingSvgTextColumns = ({
  svgtextrow,
  blockConfig,
  reverse,
  carousel,
  transparent,
}) => {
  const [emblaRef, embla] = useEmblaCarousel();

  if (!carousel)
    return (
      <BlockWrapper {...blockConfig} className="grid grid-cols-14 lg:gap-y-24">
        {svgtextrow.map((row, i) => (
          <FullCard
            key={i}
            row={[row, i]}
            reverse={reverse}
            transparent={transparent}
          />
        ))}
      </BlockWrapper>
    );

  return (
    <BlockWrapper {...blockConfig} className="">
      <div ref={emblaRef} className="overflow-hidden">
        <div className="flex px-6 lg:px-24">
          {svgtextrow.map((row, outerIndex) => (
            <div
              key={outerIndex}
              className="rounded-2xl relative overflow-hidden mx-12 min-w-full px-6 lg:px-24 grid grid-rows-2 text-center lg:text-left lg:grid-rows-1 lg:grid-cols-2 justify-center align-middle bg-slate/5 md:gap-12 py-20 lg:py-32"
            >
              <div className="flex justify-center">
                <Image
                  {...row.image}
                  className="md:max-h-48 lg:max-h-80 py-4 object-contain"
                />
              </div>
              <PortableTextBlock text={row.text} />
              <div className="absolute bottom-6 sm:bottom-12 flex justify-center w-full gap-6">
                {svgtextrow.map((item, index) => (
                  <button
                    aria-label={`navigate to carousel item ${index + 1}`}
                    className="rounded-full h-[14px] w-[14px]"
                    onClick={() => embla.scrollTo(index)}
                    style={{
                      backgroundColor:
                        index === outerIndex
                          ? "#31677B"
                          : "rgba(49, 103, 123, 0.1)",
                    }}
                  ></button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default AlternatingSvgTextColumns;
