import { graphql, useStaticQuery } from "gatsby";

export const useTeamMembers = () => {
  const { allSanityTeamMember } = useStaticQuery(graphql`
    query {
      allSanityTeamMember(sort: { fields: [orderRank], order: ASC }) {
        nodes {
          order
          orderRank
          fullName
          position
          slug {
            current
          }
          _rawHeadshotImage
          _rawFullImage
          bio: _rawBio(resolveReferences: { maxDepth: 100 })
        }
      }
    }
  `);
  return allSanityTeamMember.nodes || [];
};
