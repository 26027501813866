import { Link } from "gatsby";
import cx from "classnames";
import React from "react";
import Image from "gatsby-plugin-sanity-image";

export const NewsCard = ({ heading, slug, _rawImage, category, featured }) => {
  return (
    <div className="w-full overflow-hidden">
      {_rawImage && (
        <div className="overflow-hidden">
          <Link to={`/insights/${category.slug.current}/${slug.current}`}>
            <Image
              className={cx(
                { "h-[25rem]": featured },
                { "h-[12.5rem]": !featured },
                "transform hover:scale-105 ease-in-out duration-300 object-cover min-w-full"
              )}
              {..._rawImage}
            />
          </Link>
        </div>
      )}
      <h3
        className={cx(
          "text-teal my-3 lg:my-5 font-bold",
          { blockH3: featured },
          { blockH5: !featured }
        )}
      >
        {heading}
      </h3>
      <Link
        to={`/insights/${category.slug.current}/${slug.current}`}
        className="text-teal blockH6 font-bold"
      >
        Read more &gt;
      </Link>
    </div>
  );
};
