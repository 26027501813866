import Image from "gatsby-plugin-sanity-image";
import React from "react";
import backgroundImage from "../../../images/portrait-bg.png";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { BlockWrapper } from "../blockWrapper";

const ImageText = ({ _rawText, image, blockConfig, layout, larger }) => {
  return (
    <BlockWrapper
      className="grid grid-cols-14 gap-y-12 lg:gap-y-0"
      {...blockConfig}
    >
      <div
        className={
          layout === "right"
            ? "col-start-3 col-end-13 lg:col-end-14 lg:col-start-9 lg:ml-12 self-center lg:row-start-1 lg:row-end-2"
            : "col-start-3 lg:col-start-2 lg:mr-12 col-end-13 lg:col-end-7 self-center row-start-2 row-end-3 lg:row-start-1 lg:row-end-2"
        }
      >
        {image && (
          <div
            className="p-4 pb-16 md:p-5 md:pb-20 lg:p-7 lg:pb-28 rounded-lg bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <Image {...image} />
          </div>
        )}
      </div>
      <div
        className={
          layout === "right"
            ? "max-w-none col-start-2 col-end-14 lg:col-end-8 self-center row-start-1 row-end-2"
            : "max-w-none col-start-2 col-end-14 lg:col-start-8 self-center"
        }
      >
        <PortableTextBlock text={_rawText} />
      </div>
    </BlockWrapper>
  );
};

export default ImageText;
