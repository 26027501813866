import React from "react";

import { BlockWrapper } from "../blockWrapper";

const Quote = ({ blockConfig, backgroundsvg, quote, author }) => {
  return (
    <BlockWrapper
      {...blockConfig}
      className="grid grid-cols-14 items-center relative overflow-hidden"
    >
      <div
        dangerouslySetInnerHTML={{ __html: backgroundsvg }}
        className="relative col-start-2 col-end-14 flex justify-end quote-svg"
      ></div>
      <div className="absolute text-teal font-bold col-start-2 col-end-14 md:col-start-4 md:col-end-12">
        <p className="blockH3 leading-8 md:leading-12">{quote}</p>
        <p className="blockH6 mt-4 md:mt-8">{author}</p>
      </div>
    </BlockWrapper>
  );
};

export default Quote;
