import { useEmblaCarousel } from "embla-carousel/react";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useSpring,
} from "framer-motion";
import Image from "gatsby-plugin-sanity-image";
import React, { useState } from "react";
import { useGesture } from "react-use-gesture";

import { BlockWrapper } from "../blockWrapper";

const CarouselImage = ({ copy, heading, _rawImage }) => {
  return (
    <div
      className="w-full card-slider bg-white rounded-xl"
      style={{ flex: "0 0 auto" }}
    >
      <div className="flex items-center justify-center">
        {_rawImage && <Image {..._rawImage} className="w-2/3 h-auto py-16" />}
      </div>
      <div className="text-dark-navy p-7">
        <h5 className="pt-2 block text-lg lg:text-xl font-bold">{heading}</h5>
        <p className="text-xs lg:text-base mt-4">{copy}</p>
      </div>
    </div>
  );
};

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

const cursorTextVars = {
  visible: {
    opacity: 1,
  },
  dragging: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
};

const CardSlider = ({ blockConfig, cards }) => {
  // Embla Setup
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    align: "start",
    containScroll: true,
  });

  // Cursor Setup

  const [cusorActive, setCursorActive] = useState(false);
  const [isDrag, setDrag] = useState(false);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onDrag: ({ dragging }) => setDrag(dragging),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  if (cards.length <= 3) {
    return (
      <BlockWrapper {...blockConfig} className="px-gutter">
        <div className="flex flex-col lg:flex-row w-full gap-6">
          {cards.map((item, i) => (
            <CarouselImage key={i} {...item} />
          ))}
        </div>
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper {...blockConfig} className="px-gutter overflow-hidden">
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: "80px",
              height: "80px",
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
            className="fixed left-0 top-0 rounded-full z-2000 pointer-events-none cursor-none flex items-center justify-center drag-cursor bg-teal text-white"
            variants={cursorVars}
            initial="hidden"
            animate={isDrag ? "dragging" : "visible"}
            exit="hidden"
          >
            <motion.span className="font-sansMedium" variants={cursorTextVars}>
              Drag
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>
      <div ref={emblaRef} className="lg:-ml-4 cursor-none" {...bind()}>
        <div className="flex w-full gap-6">
          {cards.map((item, i) => (
            <CarouselImage key={i} {...item} />
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default CardSlider;
