import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

import { BlockWrapper } from "../blockWrapper";
import { DropdownToggle } from "./dropdownToggle";

const Accordion = ({ i, expanded, setExpanded, content }) => {
  const isOpen = i === expanded;
  return (
    <>
      <motion.div
        className="py-5 border-b flex flex-row justify-between items-center text-dark-navy"
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <p className="text-xl font-bold">{content.heading}</p>
        <DropdownToggle className="focus:outline-none" navOpen={isOpen} />
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{
                collapsed: { opacity: 0, scale: 1 },
                open: { opacity: 1, scale: 1 },
              }}
              transition={{ duration: 0.8 }}
              className="py-4"
            >
              <p className="text-dark-navy text-lg">{content.copy}</p>
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

const Dropdowns = ({ blockConfig, dropdowns }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 lg:col-start-4 col-end-14 lg:col-end-12">
        {dropdowns.map((row, i) => (
          <Accordion
            key={i}
            i={i}
            expanded={expanded}
            setExpanded={setExpanded}
            content={row}
          />
        ))}
      </div>
    </BlockWrapper>
  );
};

export default Dropdowns;
