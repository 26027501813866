import { wrap } from "@popmotion/popcorn";
import cx from "classnames";
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { useEffect, useState } from "react";

import useInterval from "../../hooks/useInterval";

const Slide = ({ key, heading, mainText, pagelink, subText, image }) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 400], [0, -100]);

  const slideVars = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div
      className="h-full w-full top-0 left-0 gap-y-10 z-10 col-start-1 col-end-15"
      initial="hidden"
      animate="visible"
      exit="hidden"
      key={key}
      variants={slideVars}
    >
      <div className="relative items-center grid grid-cols-14">
        <div className="w-full col-start-1 col-end-15 h-halfscreen relative lg:hidden">
          <Image
            className="absolute top-0 left-0 object-cover bg-center w-full h-full"
            {...image}
          />
        </div>
        <div className="lg:absolute z-10 text-dark-navy lg:text-white col-start-2 col-end-14 lg:col-end-7 mt-6">
          {heading && <h1 className="leading-none mb-5 blockH3">{mainText}</h1>}
          {subText && <p className="leading-8 mb-5 blockH6">{subText}</p>}
          {pagelink && (
            <Link
              className="block leading-8 blockH7 font-bold hover:opacity-75"
              to={pagelink.page.slug.current}
            >
              {pagelink.linkText}
            </Link>
          )}
        </div>
        <div className="col-start-1 col-end-15 lg:h-screen overflow-hidden">
          <motion.div style={{ y: y1, height: "120%" }}>
            <Image
              className="object-cover w-auto hidden lg:block brightness-75"
              style={{ height: "120%" }}
              {...image}
            />
            {/* <BackgroundVideo video={rest.video} /> */}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

const FeatureSlider = ({ items }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const wrappedIndex = wrap(0, items.length, pageIndex);

  const [delay] = useState(8000);

  const [isPlaying, setIsPlaying] = useState(true);
  const [inView, setInView] = useState(true);

  useInterval(
    () => {
      setPageIndex((prev) => prev + 1);
    },
    isPlaying && inView ? delay : null
  );

  if (pageIndex === items.length) {
    setPageIndex(0);
  }

  useEffect(() => {
    setIsPlaying(true);
  }, [isPlaying]);

  const spanVars = {
    initial: {
      width: "0%",
    },
    animate: {
      width: "100%",
      transition: {
        duration: delay / 1000,
        repeat: Infinity,
      },
    },
  };

  const WindowFocusHandler = () => {
    useEffect(() => {
      window.addEventListener("focus", () => setInView(true));
      window.addEventListener("blur", () => setInView(false));
      return () => {
        window.removeEventListener("focus", () => setInView(true));
        window.removeEventListener("blur", () => setInView(false));
      };
    });

    return <></>;
  };

  WindowFocusHandler();

  return (
    <div>
      <div className="relative lg:bg-dark-navy h-ninety lg:h-screen grid grid-cols-14">
        {/* Main Slide */}
        <AnimatePresence exitBeforeEnter>
          <Slide {...items[wrappedIndex]} key={pageIndex} />
        </AnimatePresence>
        {/* Slider Nav */}
        <nav className="absolute bottom-4 left-0 flex justify-around gap-4 lg:gap-16 w-full z-10 lg:bottom-16 col-start-2 col-end-14">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={cx("relative w-full cursor-pointer", {
                  "opacity-50": pageIndex !== index,
                })}
                onClick={() => {
                  pageIndex !== index && setPageIndex(index);
                  pageIndex !== index && setIsPlaying(false);
                }}
              >
                <h2 className="hidden lg:block text-white mb-4 leading-none blockH7 font-bold">
                  {item.heading}
                </h2>
                <div className="h-1 rounded-full bg-slate lg:bg-white relative">
                  {pageIndex === index && (
                    <motion.span
                      variants={inView && spanVars}
                      initial="initial"
                      animate="animate"
                      className="h-1 w-full bg-teal absolute z-10 rounded-full"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default FeatureSlider;
