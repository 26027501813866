import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import cx from "classnames";

import { BlockWrapper } from "../blockWrapper";

const Card = ({ heading, svg, copy, link, i }) => {
  return (
    <Link
      className={cx(
        "w-full bg-slate/5 rounded-2xl p-8 hover:scale-105 transform duration-300",
        {
          "mt-8 lg:mt-0": i !== 0,
        }
      )}
      to={`/${link.page.slug.current}`}
    >
      <div className="w-full h-full flex flex-col items-center text-center">
        <h4 className="blockH4 text-teal">{heading}</h4>
        <Image
          className="flex justify-center items-center my-8 mx-auto lg:my-16 max-h-44 h-full w-auto"
          {...svg}
        />
        <p className="blockH8 leading-6 text-dark-navy">{copy}</p>
        <div className="w-full h-full flex flex-col justify-end mt-8">
          <button className="border rounded-full py-4 px-10 text-white mx-auto bg-teal blockH7 font-bold ">
            Invest Now
          </button>
        </div>
      </div>
    </Link>
  );
};

const SvgCards = ({ blockConfig, cards }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14 gap-x-8 flex flex-col lg:flex-row">
        {cards.map((item, i) => {
          return <Card key={i} i={i} {...item} />;
        })}
      </div>
    </BlockWrapper>
  );
};

export default SvgCards;
