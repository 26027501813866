import cx from "classnames";
import React from "react";
import { RiskMeter } from "../../ui/riskMeter";
import { BlockWrapper } from "../blockWrapper";

const MultiCardColumns = ({ blockConfig, headings }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <div
        className="flex flex-col lg:grid gap-7 text-center px-8 max-w-7xl mx-auto"
        style={{
          gridTemplateColumns: `repeat(${headings.length}, minmax(0, 1fr))`,
        }}
      >
        {headings.map(
          (
            { heading, mainText, subText, riskLevel, linkedDocument },
            index
          ) => (
            <div
              onClick={() => {
                linkedDocument?.asset.url &&
                  window.open(linkedDocument.asset.url, "_blank");
              }}
              key={index}
              className={cx(
                "bg-slate/5 rounded-2xl py-8 px-4 grid gap-8 grid-rows-[56px_1fr_112px] justify-center align-middle text-center w-full max-w-[250px] mx-auto",
                {
                  "cursor-pointer hover:scale-[1.02] transition-all":
                    linkedDocument?.asset.url,
                }
              )}
            >
              <h3 className="text-dark-navy blockH6 font-bold">{heading}</h3>
              <p className="text-teal blockH1">{riskLevel ?? mainText}</p>
              {riskLevel ? (
                <RiskMeter condensed riskLevel={riskLevel} />
              ) : (
                <div className="flex flex-col items-center">
                  <p className="blockH9 flex-1">{subText}</p>
                  {linkedDocument && (
                    <>
                      <a
                        className="font-bold inline-block border rounded-full py-[0.625rem] px-6 bg-gold border-gold text-dark-navy hover:opacity-75"
                        href={linkedDocument.asset.url}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        Download
                      </a>
                    </>
                  )}
                </div>
              )}
            </div>
          )
        )}
      </div>
    </BlockWrapper>
  );
};

export default MultiCardColumns;
