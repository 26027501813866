import Image from "gatsby-plugin-sanity-image";
import React from "react";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { BlockWrapper } from "../blockWrapper";

const DoubleTextHero = ({ blockConfig, left, right, sub, backgroundImage }) => {
  return (
    <BlockWrapper
      {...blockConfig}
      className="text-white relative overflow-hidden"
    >
      <Image
        {...backgroundImage}
        className="absolute h-full w-full z-[-1] object-cover"
      />
      <div className="py-40 max-w-7xl m-auto">
        <div className="flex flex-col md:grid md:grid-cols-[1fr_1px_1fr] gap-4 px-6 md:px-12 text-center mb-12 lg:mb-24">
          <PortableTextBlock
            text={left}
            className="flex flex-col justify-center"
          />
          <div className="bg-white h-[1px] md:h-auto"></div>
          <PortableTextBlock
            text={right}
            className="flex flex-col justify-center"
          />
        </div>
        <PortableTextBlock
          text={sub}
          className="text-center px-6 md:px-24 lg:px-52"
        />
      </div>
    </BlockWrapper>
  );
};

export default DoubleTextHero;
