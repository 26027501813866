import cx from "classnames";
import Image from "gatsby-plugin-sanity-image";
import React from "react";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { PageLink } from "../../ui";
import { BlockWrapper } from "../blockWrapper";
import { Link } from "gatsby";

const MidTitle = ({
  blockConfig,
  link,
  text,
  skinny,
  backgroundImage,
  imageTint,
}) => {
  const tintDigit = (100 - imageTint) / 100;

  return (
    <BlockWrapper
      {...blockConfig}
      className={cx("grid grid-cols-14 text-center relative overflow-hidden", {
        "!py-48 !lg:py-64": backgroundImage,
      })}
    >
      {backgroundImage && (
        <Image
          {...backgroundImage}
          className="absolute w-full h-full object-cover z-[-1]"
          style={{
            "--tw-brightness": `brightness(${tintDigit})`,
            filter:
              "var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)",
          }}
        />
      )}
      <div
        className={cx("mx-auto", {
          "col-start-2 col-end-14 lg:col-start-5 lg:col-end-11": skinny,
          "col-start-2 col-end-14": !skinny,
        })}
      >
        <PortableTextBlock
          text={text}
          className={cx("prose prose-base lg:prose-2xl", {
            "text-dark-navy": !backgroundImage,
            "text-white": backgroundImage,
          })}
        />
        {!!link.length &&
          link.map((item) => {
            const pageLink = item._type === "pageLink";
            const commonStyles =
              "font-bold inline-block mt-8 hover:opacity-75 btn bg-teal text-white focus:outline-none cursor-pointer";
            return pageLink ? (
              <PageLink className={commonStyles} {...link} />
            ) : (
              <a href={item.path} target="_blank" className={commonStyles}>
                {item.linkText}
              </a>
            );
          })}
      </div>
    </BlockWrapper>
  );
};

export default MidTitle;
