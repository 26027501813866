import React from "react";
import Image from "gatsby-plugin-sanity-image";

const SliderImage = ({ blockImage }) => {
  return <div>{blockImage && <Image {...blockImage} />}</div>;
};

const ImageSlider = ({ items }) => {
  return (
    <div>
      {items.map((item) => (
        <SliderImage {...item} />
      ))}
    </div>
  );
};

export default ImageSlider;
