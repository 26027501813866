import { motion } from "framer-motion";
import React from "react";

// Animation
const top = {
  closed: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  opened: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

export const DropdownToggle = ({
  className,
  navOpen,
  width = 18,
  height = 18,
  color,
  ...props
}) => {
  const variant = navOpen ? "opened" : "closed";
  return (
    <button
      style={{ width: "fit-content" }}
      {...props}
      className={`${className} outline-remove`}
    >
      <svg
        className="w-4 h-4"
        width="185"
        height="185"
        viewBox="0 0 185 185"
        fill={`${(color && "#ffffff") || "#0C5964"}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.rect
          variants={top}
          animate={variant}
          initial="closed"
          x="100"
          width="185"
          height="14"
          transform="rotate(90 100 0)"
          fill={`${(color && "#ffffff") || "#0C5964"}`}
        />
        <motion.rect
          y="85"
          width="185"
          height="14"
          fill={`${(color && "#ffffff") || "#0C5964"}`}
        />
      </svg>
    </button>
  );
};
