import React from "react";

import { VideoModule } from "../../modules";
import { BlockWrapper } from "../blockWrapper";

const VideoBlock = ({ blockConfig, video }) => {
  return (
    <BlockWrapper {...blockConfig}>
      <VideoModule {...video} />
    </BlockWrapper>
  );
};

export default VideoBlock;
