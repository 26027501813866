import { useEmblaCarousel } from "embla-carousel/react";
import Image from "gatsby-plugin-sanity-image";
import React from "react";

import { BlockWrapper } from "../blockWrapper";

const CarouselImage = ({ image }) => {
  return (
    <div
      className="w-6/12 flex flex-col items-center justify-center mt-14"
      style={{ flex: "0 0 auto" }}
    >
      <div className="w-full">
        {image && (
          <Image
            {...image}
            className="mx-auto w-auto h-auto max-h-24 lg:h-full"
          />
        )}
      </div>
    </div>
  );
};

const Logo = ({ image }) => {
  return (
    <div className="w-full">
      <Image {...image} className="mx-auto w-auto max-h-36 h-full" />
    </div>
  );
};

const RecognitionLogos = ({ blockConfig, heading, logos }) => {
  // Embla Setup
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    containScroll: true,
    startIndex: 1,
  });

  return (
    <BlockWrapper
      {...blockConfig}
      className="grid grid-cols-14 overflow-hidden"
    >
      <h2 className="px-gutter text-teal blockH2 text-center leading-12 col-start-1 col-end-15">
        {heading}
      </h2>
      <div className="col-start-2 col-end-14 flex-row gap-x-6 mt-14 md:mt-20 hidden md:flex">
        {logos.map((item, i) => (
          <Logo key={i} {...item} />
        ))}
      </div>
      <div
        ref={emblaRef}
        className="-ml-4 cursor-none col-start-2 col-end-14 md:hidden"
      >
        <div className="flex w-full gap-10 lg:gap-6">
          {logos.map((item, i) => (
            <CarouselImage key={i} {...item} />
          ))}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default RecognitionLogos;
