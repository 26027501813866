import PropTypes from "prop-types";
import React from "react";
import {
  AlternatingSvgTextColumns,
  CardSlider,
  Chart,
  ComparisonChart,
  ContactForm,
  DoubleTextColumn,
  Dropdowns,
  HomePageSlider,
  ImageBlock,
  ImageSlider,
  ImageText,
  LatestNews,
  MidTitle,
  NewsTabs,
  OurTeam,
  Quote,
  RecognitionLogos,
  Resources,
  SmallPrint,
  SvgCards,
  Tabs,
  TextBlock,
  VideoBlock,
  DoubleTextHero,
  MultiCardColumns,
} from "./blocks";

const BlockZone = ({ blocks }) => {
  return blocks.map((block, i) => {
    switch (block._type) {
      case "homepageSlider":
        return <HomePageSlider {...block} key={i} />;
      case "recognitionLogos":
        return <RecognitionLogos {...block} key={i} />;
      case "svgCards":
        return <SvgCards {...block} key={i} />;
      case "alternatingSvgTextColumns":
        return <AlternatingSvgTextColumns {...block} key={i} />;
      case "midTitle":
        return <MidTitle {...block} key={i} />;
      case "quote":
        return <Quote {...block} key={i} />;
      case "latestNews":
        return <LatestNews {...block} key={i} />;
      case "textBlock":
        return <TextBlock {...block} key={i} />;
      case "imageText":
        return <ImageText {...block} key={i} />;
      case "imageBlock":
        return <ImageBlock {...block} key={i} />;
      case "videoBlock":
        return <VideoBlock {...block} key={i} />;
      case "imageSlider":
        return <ImageSlider {...block} key={i} />;
      case "resources":
        return <Resources {...block} key={i} />;
      case "doubleTextColumn":
        return <DoubleTextColumn {...block} key={i} />;
      case "dropdowns":
        return <Dropdowns {...block} key={i} />;
      case "newsTabs":
        return <NewsTabs {...block} key={i} />;
      case "ourTeam":
        return <OurTeam {...block} key={i} />;
      case "contactForm":
        return <ContactForm {...block} key={i} />;
      case "cardSlider":
        return <CardSlider {...block} key={i} />;
      case "smallPrint":
        return <SmallPrint {...block} key={i} />;
      case "tabs":
        return <Tabs {...block} key={i} />;
      case "chart":
        return <Chart {...block} key={i} />;
      case "comparisonChart":
        return <ComparisonChart {...block} key={i} />;
      case "doubleTextHero":
        return <DoubleTextHero {...block} key={i} />;
      case "multiCardColumns":
        return <MultiCardColumns {...block} key={i} />;
      default:
        return null;
    }
  });
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
