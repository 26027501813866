import React from "react";
import cx from "classnames";

const LevelIcon = ({ selectedLevel, currentLevel }) => (
  <span
    className={cx("blockH7 w-7 h-7 flex justify-center items-center", {
      "rounded-full bg-dark-navy font-bold text-white":
        selectedLevel === currentLevel,
    })}
  >
    {currentLevel}
  </span>
);

const Arrow = ({ direction }) => {
  const drawPath = {
    left: "M4.45707 0.646484L5.16418 1.35359L2.01773 4.50004H12.8106C13.0868 4.50004 13.3106 4.7239 13.3106 5.00004C13.3106 5.27618 13.0868 5.50004 12.8106 5.50004H2.01773L5.16418 8.64648L4.45707 9.35359L0.103516 5.00004L4.45707 0.646484Z",
    right:
      "M8.95699 0.646484L8.24989 1.35359L11.3963 4.50004H0.60344C0.327298 4.50004 0.10344 4.7239 0.10344 5.00004C0.10344 5.27618 0.327298 5.50004 0.60344 5.50004H11.3963L8.24989 8.64648L8.95699 9.35359L13.3105 5.00004L8.95699 0.646484Z",
  };

  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-dark-navy"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d={drawPath[direction]}
      />
    </svg>
  );
};

export const RiskMeter = ({ riskLevel, className, condensed }) => (
  <div
    className={cx("flex flex-col items-center py-5", className, {
      "border-b-2 border-teal": !condensed,
    })}
  >
    {!condensed && (
      <h3 className="blockH8 font-bold text-teal lg:hidden">Risk Indicator</h3>
    )}
    <div>
      {!condensed && <span className="block blockH1">{riskLevel}</span>}
      <span className="block blockH9 font-bold">Risk scale</span>
      <div className="flex justify-between py-3">
        {[1, 2, 3, 4, 5, 6, 7].map((level) => (
          <LevelIcon currentLevel={level} selectedLevel={riskLevel} />
        ))}
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-1 blockH10 font-bold">
          <Arrow direction="left" />
          <span>Lower Risk</span>
        </div>
        <div className="flex items-center gap-1 blockH10 font-bold">
          <span>Higher Risk</span>
          <Arrow direction="right" />
        </div>
      </div>
    </div>
  </div>
);

export default RiskMeter;
