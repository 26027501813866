import React from "react";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { BlockWrapper } from "../blockWrapper";

const DoubleTextColumn = ({ blockConfig, left, right }) => {
  return (
    <BlockWrapper
      {...blockConfig}
      className="grid grid-cols-2 gap-4 lg:gap-20 pt-12 px-8 lg:px-0"
    >
      <PortableTextBlock
        text={left}
        className="lg:ml-auto lg:pl-gutter col-start-1 lg:col-end-2 col-end-3"
      />
      <PortableTextBlock
        text={right}
        className="lg:pr-gutter col-start-1 lg:col-start-2 col-end-3"
      />
    </BlockWrapper>
  );
};

export default DoubleTextColumn;
