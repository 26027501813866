import React from "react";
import cx from "classnames";

import { BlockWrapper } from "../blockWrapper";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

const TextBlock = ({ _rawText, centerText, blockConfig }) => {
  return (
    <BlockWrapper
      className={`prose prose-base lg:prose-2xl max-w-none grid grid-cols-14 ${
        centerText && "text-center max-w-[1000px] mx-auto"
      }`}
      {...blockConfig}
    >
      <PortableTextBlock
        className={cx("prose prose-base lg:prose-2xl", {
          "col-start-2 col-end-14 lg:col-end-10": centerText !== true,
          "col-start-2 col-end-14 mx-auto": centerText === true,
        })}
        text={_rawText}
      />
    </BlockWrapper>
  );
};

export default TextBlock;
