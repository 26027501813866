import { graphql, useStaticQuery } from "gatsby";

export const useLatestPosts = () => {
  const { allSanityNewsArticle } = useStaticQuery(graphql`
    query {
      allSanityNewsArticle(sort: { fields: date, order: DESC }) {
        nodes {
          id
          heading
          author
          date
          content: _rawContent(resolveReferences: { maxDepth: 100 })
          slug {
            current
          }
          _rawImage
          category {
            slug {
              current
            }
          }
        }
      }
    }
  `);
  return allSanityNewsArticle.nodes || [];
};
