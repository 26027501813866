import cx from "classnames";
import { useEmblaCarousel } from "embla-carousel/react";
import { AnimateSharedLayout, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { BlockWrapper } from "../blockZone/blockWrapper";

const CarouselItem = ({ title, content, className, ...rest }) => {
  return (
    <div className={className}>
      <div className="grid grid-cols-14 col-start-1 col-end-15 gap-y-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          {...rest}
          className="col-start-2 col-end-14 w-full mt-10 lg:mt-24"
        >
          {content.map((row, i) => {
            switch (title) {
              case "RESOURCES":
                const { heading, excerpt, file } = row;
                return (
                  <div
                    key={i}
                    className={cx(
                      "bg-white w-full flex flex-col lg:flex-row text-dark-navy rounded-2xl p-6 lg:p-12",
                      {
                        "mt-5": i !== 0,
                      }
                    )}
                  >
                    <div className="w-full">
                      {heading && (
                        <h3 className="font-bold text-lg lg:text-xl leading-6">
                          {heading}
                        </h3>
                      )}
                      {excerpt && (
                        <p className="text-base my-2 leading-6">{excerpt}</p>
                      )}
                    </div>

                    <div className="w-full flex lg:justify-end items-center">
                      {file && (
                        <a
                          className="font-bold text-teal text-base lg:text-xl focus:outline-none"
                          href={file.asset.url}
                          target="_blank"
                          rel="noreferrer"
                          download
                        >
                          Download
                        </a>
                      )}
                    </div>
                  </div>
                );

              default:
                return (
                  <div
                    key={i}
                    className={cx(
                      "grid grid-cols-3 py-3 text-xs lg:text-base text-dark-navy",
                      {
                        "border-b border-dark-navy/20":
                          i !== content.length - 1,
                      }
                    )}
                  >
                    <div className="col-start-1 col-end-3 lg:col-end-2 font-bold">
                      <p>{row.heading}</p>
                    </div>
                    <div className="col-start-1 lg:col-start-2 col-end-4">
                      <p>{row.copy}</p>
                    </div>
                  </div>
                );
            }
          })}
        </motion.div>
      </div>
    </div>
  );
};

const TabsCarousel = ({ blockConfig, items }) => {
  // Define Embla Carousels

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [carouselRef, carouselEmbla] = useEmblaCarousel();

  const [navRef, navEmbla] = useEmblaCarousel({
    containScroll: "keepSnaps",
    selectedClass: "",
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!carouselEmbla || !navEmbla) return;
      if (navEmbla.clickAllowed()) carouselEmbla.scrollTo(index);
    },
    [navEmbla, carouselEmbla]
  );

  const onSelect = useCallback(() => {
    if (!carouselEmbla || !navEmbla) return;
    setSelectedIndex(carouselEmbla.selectedScrollSnap());
    navEmbla.scrollTo(carouselEmbla.selectedScrollSnap());
  }, [carouselEmbla, navEmbla, setSelectedIndex]);

  useEffect(() => {
    if (!carouselEmbla) return;
    onSelect();
    carouselEmbla.on("select", onSelect);
  }, [carouselEmbla, onSelect]);

  return (
    <>
      <BlockWrapper
        className="overflow-x-hidden grid grid-cols-14 bg-brandDark"
        {...blockConfig}
      >
        <nav
          className="row-start-2 col-start-1 col-end-15 lg:flex lg:justify-center border-b border-dark-navy"
          ref={navRef}
        >
          <div className="flex">
            <AnimateSharedLayout>
              {items.map((navText, index) => {
                const isActive = selectedIndex === index;
                return (
                  <div key={index} className="flex-slide-item mr-4 lg:mx-4">
                    <motion.button
                      className={`inline-block cursor-pointer px-8 md:px-8 py-2 rounded-full relative flex flex-col items-center ${
                        isActive ? "text-brandMain font-semibold" : "text-white"
                      }`}
                      key={index}
                      animate={{ opacity: isActive ? 1 : 0.5 }}
                      onClick={() => onThumbClick(index)}
                    >
                      <div className="relative z-10 text-dark-navy text-xs uppercase">
                        {navText.title}
                      </div>
                      {isActive && (
                        <motion.span
                          layoutId="line"
                          className="w-3/4 h-1 bg-dark-navy block absolute"
                          style={{ bottom: "-2px" }}
                        />
                      )}
                    </motion.button>
                  </div>
                );
              })}
            </AnimateSharedLayout>
          </div>
        </nav>
        <div
          className="row-start-3 col-start-1 col-end-15 lg:col-start-2lg:col-end-14"
          ref={carouselRef}
        >
          <article className="w-full flex cursor-grab">
            {items.map((item, index) => {
              return (
                <CarouselItem
                  key={index}
                  className="flex-slide-item w-full"
                  {...item}
                />
              );
            })}
          </article>
        </div>
      </BlockWrapper>
    </>
  );
};

export default TabsCarousel;
