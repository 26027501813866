const handleDownload = async (url, filename) => {
  try {
    const response = await fetch(
      `/.netlify/functions/downloadFile?url=${encodeURIComponent(url)}`
    );
    const { data, contentType } = await response.json();

    const blob = new Blob(
      [Uint8Array.from(atob(data), (c) => c.charCodeAt(0))],
      { type: contentType }
    );
    const downloadUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Download failed', error);
  }
};

export default handleDownload;
