import cx from 'classnames';
import { useEmblaCarousel } from 'embla-carousel/react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useState } from 'react';
import TabsCarousel from '../../modules/tabsCarousel';
import { BlockWrapper } from '../blockWrapper';
import handleDownload from '../../utils/convertUrl';

const ActiveTab = ({ title, content, ...rest }) => {
  switch (title) {
    case 'RESOURCES':
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          {...rest}
        >
          {content.map((doc, i) => {
            const { heading, excerpt, file } = doc;
            return (
              <div
                key={i}
                className={cx(
                  'bg-white w-full flex flex-col lg:flex-row text-teal rounded-2xl p-6 lg:p-12',
                  {
                    'mt-24': i === 0,
                    'mt-5': i !== 0,
                  }
                )}
              >
                <div className='w-full'>
                  {heading && (
                    <h3 className='font-bold text-lg lg:text-xl leading-6'>
                      {heading}
                    </h3>
                  )}
                  {excerpt && (
                    <p className='text-base my-2 leading-6'>{excerpt}</p>
                  )}
                </div>

                <div className='w-full flex lg:justify-end items-center'>
                  {file && (
                    <a
                      className='font-bold text-teal text-base lg:text-xl focus:outline-none'
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        const customFilename = `${file.asset.filename}`;
                        handleDownload(file.asset.url, customFilename);
                      }}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Download
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </motion.div>
      );

    default:
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          {...rest}
          className='w-full mt-10 lg:mt-24'
        >
          {content.map((row, i) => (
            <div
              key={i}
              className={cx('grid grid-cols-3 py-3 font-bold', {
                'border-b border-teal': i !== content.length - 1,
              })}
            >
              <div className='col-start-1 col-end-3 lg:col-end-2 text-teal'>
                <p>{row.heading}</p>
              </div>
              <div className='col-start-1 lg:col-start-2 col-end-4 text-dark-navy'>
                <p>{row.copy}</p>
              </div>
            </div>
          ))}
        </motion.div>
      );
  }
};

const TabsDesk = ({ items }) => {
  const [activeTab, setActiveTab] = useState(0);

  // Embla Setup
  const [emblaRef] = useEmblaCarousel({
    loop: false,
    dragFree: true,
    containScroll: true,
    startIndex: 0,
  });

  return (
    <div className='grid grid-cols-14 overflow-hidden'>
      <div
        ref={emblaRef}
        className='cursor-none col-start-1 col-end-15 lg:col-start-2 lg:col-end-14'
      >
        <AnimateSharedLayout>
          <div className='flex flex-row lg:justify-between text-teal'>
            {items.map((tab, i) => (
              <div
                className='w-full tab text-center relative border-b border-teal flex flex-col items-center'
                key={i}
              >
                <button
                  onClick={() => setActiveTab(i)}
                  className={cx(
                    'focus:outline-none text-xs lg:text-lg transition-all duration-300 ease-in-out pb-6 w-max uppercase',
                    {
                      'font-black font-bold': i === activeTab,
                      'font-normal': i !== activeTab,
                    }
                  )}
                >
                  {tab.title}
                </button>
                {activeTab === i && (
                  <motion.span
                    layoutId='line'
                    className='w-3/4 lg:w-full h-1 bg-teal block absolute'
                    style={{ bottom: '-2px' }}
                  />
                )}
              </div>
            ))}
          </div>
        </AnimateSharedLayout>
      </div>
      <div className='col-start-2 col-end-14 lg:col-start-3 lg:col-end-13'>
        <AnimatePresence exitBeforeEnter>
          <ActiveTab key={activeTab} {...items[activeTab]} />
        </AnimatePresence>
      </div>
    </div>
  );
};
const Tabs = ({ blockConfig, items, resources }) => {
  const breakpoints = useBreakpoint();

  const SLIDE_COUNT = items.length;
  const slides = Array.from(Array(SLIDE_COUNT).keys());

  const documents = {
    title: 'RESOURCES',
    content: resources,
  };

  const allItems = items.concat(documents);

  return (
    <BlockWrapper {...blockConfig} className='bg-slate/5'>
      {!breakpoints.md && <TabsDesk items={allItems} />}
      {breakpoints.md && <TabsCarousel slides={slides} items={allItems} />}
    </BlockWrapper>
  );
};

export default Tabs;
