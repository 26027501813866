import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React from "react";
import { useTeamMembers } from "../../../GraphQl/useTeamMembers";
import { BlockWrapper } from "../blockWrapper";
import { useEffect } from "react";

const Person = ({ _rawHeadshotImage, fullName, position }) => (
  <div className="leading-6 text-teal blockH7 overflow-hidden">
    {_rawHeadshotImage && (
      <div className="overflow-hidden">
        <Image
          {..._rawHeadshotImage}
          className="aspect-1 object-cover hover:scale-105 ease-in-out duration-300"
        />
      </div>
    )}
    <h5 className="font-bold mt-6">{fullName}</h5>
    {position && <p>{position}</p>}
  </div>
);

const OurTeam = ({ blockConfig, heading }) => {
  const teamMembers = useTeamMembers();

  useEffect(() => {
    // if the url has a #anchor, scroll to it
    if (window.location.hash) {
      const anchor = window.location.hash.replace("#", "");
      const element = document.getElementById(anchor);
      if (element) {
        window.scrollTo({
          top: element.getBoundingClientRect().y,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14 mb-[12.5rem]">
      <h3
        id="our-team"
        className="blockH3 leading-10 text-teal col-start-2 col-end-14 lg:col-end-8"
      >
        {heading}
      </h3>

      <div className="col-start-2 col-end-14 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-12 md:gap-y-24 mt-14">
        {teamMembers.map((person, index) => (
          <Link key={index} to={`/${person.slug.current}`}>
            <Person key={index} {...person} />
          </Link>
        ))}
      </div>
    </BlockWrapper>
  );
};

export default OurTeam;
