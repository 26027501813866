import React from "react";

import { BlockWrapper } from "../blockWrapper";
import { PortableTextBlock } from "../../sanity/portableTextBlock";

const SmallPrint = ({ blockConfig, _rawText }) => {
  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <PortableTextBlock
        className="prose prose-xs col-start-2 col-end-14"
        text={_rawText}
      />
    </BlockWrapper>
  );
};

export default SmallPrint;
