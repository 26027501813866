import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { BlockWrapper } from '../blockWrapper';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { useAtom } from 'jotai';
import { pathNameAtom } from '../../../lib/jotai';
import { useLocation } from '@reach/router';
import handleDownload from '../../utils/convertUrl';

const Filter = ({ options, onOptionSelect, groupName, selected }) => {
  return (
    <fieldset className='col-start-2 col-end-14 blockH7 uppercase text-teal flex text-center'>
      <AnimateSharedLayout>
        {options.map((option) => (
          <label
            key={option}
            className={cx(
              'transition-standard w-full blockH7 border-b-[1px] border-teal pb-8 relative',
              {
                'font-bold': option === selected,
              }
            )}
          >
            <input
              onChange={() => {
                onOptionSelect(option);
                const url = new URL(window.location);
                url.searchParams.set('category', option);
                window.history.pushState({}, '', url);
              }}
              type='radio'
              name={groupName}
              value={option}
              checked={option === selected}
              className='sr-only'
            />
            {option}
            {selected === option && (
              <motion.div
                layoutId='border'
                className='absolute bottom-0 h-[3px] bg-teal w-full -mb-[2px]'
              />
            )}
          </label>
        ))}
      </AnimateSharedLayout>
    </fieldset>
  );
};

const FundFilter = ({ onClick, isActive, className, fundNames }) => {
  return (
    <div className={cx(className, 'space-x-5')}>
      {fundNames.map((item) => {
        return (
          <div className='space-x-10' key={item.title}>
            <button
              onClick={onClick}
              value={item.value}
              className={cx(
                'rounded-full text-teal hover:bg-teal hover:text-white font-bold appearance-none text-center py-2 px-5 w-full md:w-auto',
                isActive === item.value
                  ? 'bg-teal text-white'
                  : 'bg-teal/10 text-teal'
              )}
            >
              {item.title}
            </button>
          </div>
        );
      })}
    </div>
  );
};

const Sort = ({
  className,
  onChange,
  defaultOption,
  isBright,
  options,
  hasArrow,
}) => {
  return (
    <>
      <label className='sr-only' htmlFor='sort-documents'>
        Choose a document sort method:
      </label>
      <select
        className={cx(
          className,
          'rounded-full text-teal font-bold text-center py-2 px-5 md:w-auto',
          isBright ? 'bg-teal/10' : 'bg-slate/10',
          hasArrow ? 'down-arrow' : 'appearance-none'
        )}
        name='sort-documents'
        id='sort-documents'
        defaultValue={defaultOption}
        onChange={onChange}
      >
        <option hidden value={defaultOption} disabled>
          {defaultOption}
        </option>
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
      </select>
    </>
  );
};

export const Document = ({ file, heading, excerpt, index }) => {
  return (
    <div
      className={cx(
        'bg-slate/5 w-full text-dark-navy rounded-2xl p-6 lg:p-12 grid grid-cols-1 lg:grid-cols-3 gap-y-5 lg:gap-0',
        {
          'mt-5': index !== 0,
        }
      )}
    >
      <div className='col-start-1 col-end-3'>
        {heading && (
          <h3 className='blockH6 font-bold leading-6 text-teal'>{heading}</h3>
        )}
        {excerpt && (
          <p className='blockH7 my-2 leading-6 text-dark-navy'>{excerpt}</p>
        )}
      </div>
      {file.asset?.url && (
        <a
          className='blockH6 font-bold text-teal focus:outline-none flex items-center justify-center lg:justify-end'
          href='#'
          onClick={(e) => {
            e.preventDefault();
            const customFilename = `${file.asset.filename}`;
            handleDownload(file.asset.url, customFilename);
          }}
          target='_blank'
          rel='noreferrer'
        >
          Download
        </a>
      )}
    </div>
  );
};

const Resources = ({ blockConfig, resources }) => {
  const [selectedCategory, setSelectedCategory] = useState('Fund Reports');
  const [sortMethod, setSortMethod] = useState(null);
  const [docsAmount, setDocsAmount] = useState(6);
  const [selectedFund, setSelectedFund] = useState('all');
  const [pathName] = useAtom(pathNameAtom);

  const isActive = selectedFund;
  const fundNames = [
    { title: 'All Funds', value: 'all' },
    { title: '5 Oceans Fund', value: 'oceansFund' },
    { title: 'Ranger Fund', value: 'rangerFund' },
    { title: 'Trans-Tasman', value: 'transFund' },
  ];
  const sortTypes = [
    { title: 'Name', value: 'name' },
    { title: 'Date Created', value: 'dateCreated' },
    { title: 'Date Updated', value: 'dateUpdated' },
  ];

  useEffect(() => {
    if (pathName) {
      setSelectedCategory('Documents');
    }
    switch (pathName) {
      case '/5-oceans-fund':
        setSelectedFund('oceansFund');
        break;
      case '/ranger-fund':
        setSelectedFund('rangerFund');
        break;
      case '/trans-tasman-fund':
        setSelectedFund('transFund');
        break;
      default:
        setSelectedCategory('Fund Reports');
        break;
    }
  }, [pathName]);

  const sortDocuments = (a, b) => {
    if (sortMethod === 'name') {
      return a.heading.localeCompare(b.heading);
    }
    if (sortMethod === 'dateCreated') {
      return new Date(a.file._createdAt) - new Date(b.file._createdAt);
    }
    if (sortMethod === 'dateUpdated') {
      return (
        new Date(a.file.asset._updatedAt) - new Date(b.file.asset._updatedAt)
      );
    }
  };

  useEffect(() => {
    setDocsAmount(6);
  }, [selectedCategory, selectedFund]);

  const handleMore = () => {
    setDocsAmount(allFiltersAppliedDocs.length);
  };

  const allFiltersAppliedDocs = resources
    .filter((doc) => doc.category === selectedCategory)
    .sort(sortDocuments)
    .filter((doc) => {
      if (selectedFund === 'all') {
        return doc;
      } else {
        return doc.fundName === selectedFund;
      }
    });

  // grab query parameter for fundType
  const location = useLocation();
  const fundType = new URLSearchParams(location.search).get('fund');
  const categoryType = new URLSearchParams(location.search).get('category');

  useEffect(() => {
    if (fundType) {
      setSelectedFund(fundType);
    }
    if (categoryType) {
      setSelectedCategory(categoryType);
    }
  }, [fundType, categoryType]);

  const handleFilterClick = (e) => {
    setSelectedFund(e.target.value);
    // update query parameter
    const url = new URL(window.location);
    url.searchParams.set('fund', e.target.value);

    window.history.pushState({}, '', url);
  };

  return (
    <BlockWrapper {...blockConfig} className='grid grid-cols-14'>
      <Filter
        options={['Documents', 'Fund Reports', 'Forms']}
        groupName='document-category'
        onOptionSelect={setSelectedCategory}
        selected={selectedCategory}
      />
      <div className='col-start-2 col-end-14 flex flex-col gap-x-5 lg:mx-12'>
        <div className='flex flex-col md:flex-row blockH8 gap-y-3 md:gap-y-0 justify-between my-10'>
          {/* Fund Filter */}
          <FundFilter
            onClick={handleFilterClick}
            isActive={isActive}
            className='hidden md:flex'
            fundNames={fundNames}
          />
          <Sort
            onChange={(e) => setSelectedFund(e.target.value)}
            defaultOption='Ranger Fund'
            options={fundNames}
            className='flex md:hidden'
            isBright
            hasArrow
          />
          {/* Sort by */}
          <Sort
            onChange={(e) => setSortMethod(e.target.value)}
            defaultOption='Sort by'
            options={sortTypes}
          />
        </div>
        {allFiltersAppliedDocs.slice(0, docsAmount).map((doc, index) => (
          <Document key={index} index={index} {...doc} />
        ))}
        {docsAmount < allFiltersAppliedDocs.length && (
          <button
            className='flex mx-auto bg-teal text-white px-8 py-4 rounded-full font-bold blockH8 mt-12'
            onClick={handleMore}
          >
            Show more
          </button>
        )}
      </div>
    </BlockWrapper>
  );
};

export default Resources;
