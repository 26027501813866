import cx from 'classnames';
import Image from 'gatsby-plugin-sanity-image';
import React from 'react';
import RiskMeter from '../../ui/riskMeter';
import { BlockWrapper } from '../blockWrapper';

const ComparisonChart = ({
  blockConfig,
  headingText,
  logos,
  oceansChart,
  rangerChart,
  tasmanChart,
}) => {
  const funds = [
    {
      svg: logos[0].svg,
      heading: logos[0].heading,
      risk: oceansChart.riskIndicator,
      return: oceansChart.returnTarget,
      horizon: oceansChart.recommendedInvestment,
      initial: oceansChart.minInitialInvestment,
      additional: oceansChart.minAdditionalInvestment,
      distribution: oceansChart.distribution,
      buySell: oceansChart.buySellSpread,
      management: oceansChart.managementFee,
      performance: oceansChart.performanceFee,
    },
    {
      svg: logos[1].svg,
      heading: logos[1].heading,
      risk: rangerChart.riskIndicator,
      return: rangerChart.returnTarget,
      horizon: rangerChart.recommendedInvestment,
      initial: rangerChart.minInitialInvestment,
      additional: rangerChart.minAdditionalInvestment,
      distribution: rangerChart.distribution,
      buySell: rangerChart.buySellSpread,
      management: rangerChart.managementFee,
      performance: rangerChart.performanceFee,
    },
    {
      svg: logos[2].svg,
      heading: logos[2].heading,
      risk: tasmanChart.riskIndicator,
      return: tasmanChart.returnTarget,
      horizon: tasmanChart.recommendedInvestment,
      initial: tasmanChart.minInitialInvestment,
      additional: tasmanChart.minAdditionalInvestment,
      distribution: tasmanChart.distribution,
      buySell: tasmanChart.buySellSpread,
      management: tasmanChart.managementFee,
      performance: tasmanChart.performanceFee,
    },
  ];

  const FundDetail = ({ heading, copy, isSmallText, rowIndex, colIndex }) => (
    <div
      className='border-b-2 border-teal py-6 lg:pt-6 lg:px-12'
      style={{
        gridColumnStart: colIndex,
        gridColumnEnd: colIndex + 1,
        gridRowStart: rowIndex,
        gridRowEnd: rowIndex + 1,
      }}
    >
      <h3 className='blockH8 font-bold text-teal lg:hidden'>{heading}</h3>
      <div>
        <span
          className={cx('block blockH7 font-bold', { blockH9: isSmallText })}
        >
          {copy}
        </span>
      </div>
    </div>
  );

  return (
    <BlockWrapper
      {...blockConfig}
      className='grid grid-cols-14 py-12 my-12 lg:py-24 lg:my-48 bg-slate/5'
    >
      <p className='col-start-2 col-end-14 blockH4 text-center text-teal'>
        {headingText}
      </p>

      <div className='col-start-2 col-end-14 text-center lg:grid lg:grid-cols-4'>
        {/* col 1, rows 1 - 10 */}
        {[
          '',
          'Risk Indicator',
          'Return Target',
          'Recommended Investment Horizon',
          'Minimum Initial Investment',
          'Minimum Additional Investment',
          'Distribution',
          'Buy/Sell Spread',
          'Management Fee',
          'Performance Fee',
        ].map((heading, index) => {
          return (
            <h3
              className={cx(
                'hidden lg:flex blockH8 font-bold text-teal col-start-1 col-end-2 items-center border-b-teal border-b-2 pl-6',
                `row-start-${index + 1} row-end-${index + 2}`
              )}
            >
              {heading}
            </h3>
          );
        })}

        {/* cols 2 - 4 */}
        {funds.map((fund, index) => {
          const colIndex = index + 2;
          const fundDetails = [
            { heading: 'Return Target', copy: fund.return },
            {
              heading: 'Recommended Investment Horizon',
              copy: fund.horizon,
            },
            {
              heading: 'Minimum Initial Investment',
              copy: fund.initial,
            },
            {
              heading: 'Minimum Additional Investment',
              copy: fund.additional,
            },
            { heading: 'Distribution', copy: fund.distribution },
            { heading: 'Buy/Sell Spread', copy: fund.buySell },
            { heading: 'Management Fee', copy: fund.management },
            { heading: 'Performance Fee', copy: fund.performance },
          ];

          return (
            <>
              {/* row 1 */}
              <div
                className={cx(
                  'border-b-2 border-teal lg:row-start-[1] lg:row-end-[2] mt-24 lg:mt-0',
                  `lg:col-start-[${colIndex}] lg:col-end-[${colIndex + 1}]`
                )}
              >
                <Image
                  className='flex justify-center items-center mx-auto lg:my-16 max-h-44'
                  {...fund.svg}
                />
                <h2 className='blockH5 font-bold text-teal pb-6'>
                  <span className='block'>Castle Point</span>
                  <span className='block'>{fund.heading}</span>
                </h2>
              </div>

              {/* row 2 */}
              <RiskMeter
                riskLevel={fund.risk}
                className='lg:row-start-2 lg:row-end-3'
              />

              {/* rows 3 - 10 */}
              {fundDetails.map(({ heading, copy }, index) => {
                const rowIndex = index + 3;
                const isFinalItem = index === fundDetails.length - 1;
                return (
                  <FundDetail
                    heading={heading}
                    copy={copy}
                    rowIndex={rowIndex}
                    colIndex={colIndex}
                    isSmallText={isFinalItem}
                  />
                );
              })}
            </>
          );
        })}
      </div>
    </BlockWrapper>
  );
};

export default ComparisonChart;
