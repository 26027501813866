import React from "react";
import { useLatestPosts } from "../../../GraphQl/useLatestPosts";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { NewsCard } from "../../ui/newsCard";
import { BlockWrapper } from "../blockWrapper";

const LatestNews = ({ blockConfig, text }) => {
  const posts = useLatestPosts();
  const featuredPosts = posts.slice(0, 2);
  const otherPosts = posts.slice(2);

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div className="col-start-2 col-end-14">
        {text && (
          <PortableTextBlock
            text={text}
            className="text-center text-teal py-16 blockH1"
          />
        )}
      </div>

      <div className="col-start-2 col-end-14 my-16 lg:my-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-x-12 lg:gap-y-24 mb-24">
          {featuredPosts.map((post, i) => (
            <NewsCard key={i} {...post} featured />
          ))}
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-x-12 lg:gap-y-24">
          {otherPosts.map((post, i) => {
            return <NewsCard key={i} {...post} />;
          })}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default LatestNews;
