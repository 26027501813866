import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { BlockWrapper } from "../blockWrapper";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import cx from "classnames";

const ImageBlock = ({ blockConfig, image, layout }) => {
  const { scrollY } = useViewportScroll();

  const y1 = useTransform(scrollY, [750, 1500], [-25, -100]);
  const y2 = useTransform(scrollY, [250, 1000], [-15, -85]);

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14">
      <div
        className={cx("relative", {
          "col-start-3 lg:col-start-2 col-end-13": layout === "left",
          "col-start-3 lg:col-start-4 col-end-13 lg:col-end-14":
            layout === "right",
          "col-start-3 lg:col-start-2 col-end-13 lg:col-end-14":
            layout === "full",
          "col-start-3 col-end-13": layout === "center",
        })}
      >
        {image && <Image {...image} className="z-20 relative" />}
        <motion.div
          style={{ y: y1, x: "-25px" }}
          className="backdrop hidden lg:block"
        />
        <motion.div
          style={{ y: y2, x: "-15px" }}
          className="backdrop lg:hidden"
        />
      </div>
    </BlockWrapper>
  );
};

export default ImageBlock;
